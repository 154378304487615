<template>
  <div>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="accent"
          icon="mdi-list-status"
          inline
          class="px-5 py-3 mt-7"
          style="width: 100%"
        >
          <v-row
            no-gutters
            justify="end"
            align="center"
            class="text-center"
          >
            <span v-if="!isLoadingLastUrlShortenerUpdateTime">Url shorteners last updated on: {{ this.$_global_parseAsUtc(lastClickedTime) | displayDateTimeFormat }}.</span>
            <span>Click to update Url shorteners list</span>
            <v-btn
              class="ma-2"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="handleRefreshUrlShortenersClick"
            >
              Refresh
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-card
                class="mb-3"
                elevation="10"
              >
                <v-card-title>
                  High risk words
                </v-card-title>
                <v-card-subtitle>
                  -50 points
                </v-card-subtitle>
                <v-list
                  style="max-height: 300px"
                  class="overflow-y-auto"
                >
                  <v-list-item-group
                    v-model="selectedWordHr"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in results.highRiskspamWords"
                      :key="i"
                      :value="item"
                      :disabled="expandHr"
                      style="font-size: .9rem"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"
                            :input-value="active"
                            :disabled="expandHr"
                          />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Word }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider />
                <v-row class="mt-2 ml-2 pb-2">
                  <v-btn
                    color="accent"
                    fab
                    x-small
                    :disabled="expandHrDesc"
                    @click="expandHr = !expandHr"
                  >
                    <v-icon>{{ !expandHr ? 'mdi-plus' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandHr ? 'Add' : 'Close' }}</span>
                  <v-btn
                    class="ml-15"
                    color="error"
                    fab
                    x-small
                    :disabled="selectedWordHr === undefined || selectedWordHr === '' || expandHr"
                    @click="expandHrDesc = !expandHrDesc"
                  >
                    <v-icon>{{ !expandHrDesc ? 'mdi-delete' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandHrDesc ? 'Remove' : 'Close' }}</span>
                </v-row>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="validRemove"
                  >
                    <v-row
                      v-show="expandHrDesc"
                      no-gutters
                      class="mt-1"
                    >
                      <v-text-field
                        v-model="highRiskDesc"
                        class="ml-2 mr-2"
                        :rules="[rules.required]"
                        label="Description"
                      />
                      <v-btn
                        class="mt-2 ml-2 mb-2"
                        color="primary"
                        :disabled="!validRemove"
                        :loading="addWordLoading"
                        @click="removeSpamWordToJSON(selectedWordHr, highRiskDesc, 'hr')"
                      >
                        remove
                      </v-btn>
                    </v-row>
                  </v-form>
                </v-expand-transition>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-row
                      v-show="expandHr"
                      no-gutters
                      class="mt-1"
                    >
                      <v-col>
                        <v-text-field
                          v-model="highRisk"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Add Word"
                        />
                        <v-text-field
                          v-model="highRiskDesc"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Description"
                        />
                        <v-btn
                          class="mt-2 ml-2 mb-2"
                          color="primary"
                          :disabled="!valid"
                          :loading="addWordLoading"
                          @click="addSpamWordToJSON(highRisk, highRiskDesc, 'hr', results.highRiskspamWords)"
                        >
                          add
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="mb-3"
                elevation="10"
              >
                <v-card-title>
                  Medium risk words
                </v-card-title>
                <v-card-subtitle>
                  -5 points
                </v-card-subtitle>
                <v-list
                  style="max-height: 300px"
                  class="overflow-y-auto"
                >
                  <v-list-item-group
                    v-model="selectedWordMr"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in results.mediumRiskspamWords"
                      :key="i"
                      :value="item"
                      :disabled="expandMr"
                      style="font-size: .9rem"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"
                            :input-value="active"
                            :disabled="expandMr"
                          />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Word }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider />
                <v-row class="mt-2 ml-2 pb-2">
                  <v-btn
                    color="accent"
                    fab
                    x-small
                    :disabled="expandMrDesc"
                    @click="expandMr = !expandMr"
                  >
                    <v-icon>{{ !expandMr ? 'mdi-plus' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandMr ? 'Add' : 'Close' }}</span>
                  <v-btn
                    class="ml-15"
                    color="error"
                    fab
                    x-small
                    :disabled="selectedWordMr === undefined || selectedWordMr === '' || expandMr"
                    @click="expandMrDesc = !expandMrDesc"
                  >
                    <v-icon>{{ !expandMrDesc ? 'mdi-delete' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandMrDesc ? 'Remove' : 'Close' }}</span>
                </v-row>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="validRemove"
                  >
                    <v-row
                      v-show="expandMrDesc"
                      no-gutters
                      class="mt-1"
                    >
                      <v-text-field
                        v-model="mediumRiskDesc"
                        class="ml-2 mr-2"
                        :rules="[rules.required]"
                        label="Description"
                      />
                      <v-btn
                        class="mt-2 ml-2 mb-2"
                        color="primary"
                        :disabled="!validRemove"
                        :loading="addWordLoading"
                        @click="removeSpamWordToJSON(selectedWordMr, mediumRiskDesc, 'mr')"
                      >
                        remove
                      </v-btn>
                    </v-row>
                  </v-form>
                </v-expand-transition>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-row
                      v-show="expandMr"
                      no-gutters
                      class="mt-1"
                    >
                      <v-col>
                        <v-text-field
                          v-model="mediumRisk"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Add Word"
                        />
                        <v-text-field
                          v-model="mediumRiskDesc"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Description"
                        />
                        <v-btn
                          class="mt-2 ml-2 mb-2"
                          color="primary"
                          :disabled="!valid"
                          :loading="addWordLoading"
                          @click="addSpamWordToJSON(mediumRisk, mediumRiskDesc, 'mr', results.mediumRiskspamWords)"
                        >
                          add
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="mb-3"
                elevation="10"
              >
                <v-card-title>
                  Pair words
                </v-card-title>
                <v-card-subtitle>
                  -5 points
                </v-card-subtitle>
                <v-list
                  style="max-height: 300px"
                  class="overflow-y-auto"
                >
                  <v-list-item-group
                    v-model="selectedWordPr"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in results.pairRiskspamWords"
                      :key="i"
                      :value="item"
                      :disabled="expandPr"
                      style="font-size: .9rem"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"
                            :input-value="active"
                            :disabled="expandPr"
                          />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Word }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider />
                <v-row class="mt-2 ml-2 pb-2">
                  <v-btn
                    color="accent"
                    fab
                    x-small
                    :disabled="expandPrDesc"
                    @click="expandPr = !expandPr"
                  >
                    <v-icon>{{ !expandPr ? 'mdi-plus' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandPr ? 'Add' : 'Close' }}</span>
                  <v-btn
                    class="ml-15"
                    color="error"
                    fab
                    x-small
                    :disabled="selectedWordPr === undefined || selectedWordPr === '' || expandPr"
                    @click="expandPrDesc = !expandPrDesc"
                  >
                    <v-icon>{{ !expandPrDesc ? 'mdi-delete' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandPrDesc ? 'Remove' : 'Close' }}</span>
                </v-row>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="validRemove"
                  >
                    <v-row
                      v-show="expandPrDesc"
                      no-gutters
                      class="mt-1"
                    >
                      <v-text-field
                        v-model="pairRiskDesc"
                        class="ml-2 mr-2"
                        :rules="[rules.required]"
                        label="Description"
                      />
                      <v-btn
                        class="mt-2 ml-2 mb-2"
                        color="primary"
                        :disabled="!validRemove"
                        :loading="addWordLoading"
                        @click="removeSpamWordToJSON(selectedWordPr, pairRiskDesc, 'pr')"
                      >
                        remove
                      </v-btn>
                    </v-row>
                  </v-form>
                </v-expand-transition>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-row
                      v-show="expandPr"
                      no-gutters
                      class="mt-1"
                    >
                      <v-col>
                        <v-text-field
                          v-model="pairRisk"
                          class="ml-2 mr-2"
                          :rules="[rules.required, rules.pairWord]"
                          label="Add Word"
                        />
                        <v-text-field
                          v-model="pairRiskDesc"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Description"
                        />
                        <v-btn
                          class="mt-2 ml-2 mb-2"
                          color="primary"
                          :disabled="!valid"
                          :loading="addWordLoading"
                          @click="addSpamWordToJSON(pairRisk, pairRiskDesc, 'pr', results.pairRiskspamWords)"
                        >
                          add
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="mb-3"
                elevation="10"
              >
                <v-card-title>
                  Numerals
                </v-card-title>
                <v-card-subtitle>
                  -5 points
                </v-card-subtitle>
                <v-list
                  style="max-height: 300px"
                  class="overflow-y-auto"
                >
                  <v-list-item-group
                    v-model="selectedWordNr"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in results.numeralRiskspamWords"
                      :key="i"
                      :value="item"
                      :disabled="expandNr"
                      style="font-size: .9rem"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :on-icon="'mdi-checkbox-marked-circle'"
                            :off-icon="'mdi-checkbox-blank-circle-outline'"
                            :input-value="active"
                            :disabled="expandNr"
                          />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.Word }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider />
                <v-row class="mt-2 ml-2 pb-2">
                  <v-btn
                    color="accent"
                    fab
                    x-small
                    :disabled="expandNrDesc"
                    @click="expandNr = !expandNr"
                  >
                    <v-icon>{{ !expandNr ? 'mdi-plus' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">{{ !expandNr ? 'Add' : 'Close' }}</span>
                  <v-btn
                    class="ml-15"
                    color="error"
                    fab
                    x-small
                    :disabled="selectedWordNr === undefined || selectedWordNr === '' || expandNr"
                    @click="expandNrDesc = !expandNrDesc"
                  >
                    <v-icon>{{ !expandNrDesc ? 'mdi-delete' : 'mdi-close' }}</v-icon>
                  </v-btn>
                  <span class="ml-2">Remove</span>
                </v-row>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="validRemove"
                  >
                    <v-row
                      v-show="expandNrDesc"
                      no-gutters
                      class="mt-1"
                    >
                      <v-text-field
                        v-model="numeralRiskDesc"
                        class="ml-2 mr-2"
                        :rules="[rules.required]"
                        label="Description"
                      />
                      <v-btn
                        class="mt-2 ml-2 mb-2"
                        color="primary"
                        :disabled="!validRemove"
                        :loading="addWordLoading"
                        @click="removeSpamWordToJSON(selectedWordNr, numeralRiskDesc, 'nr')"
                      >
                        remove
                      </v-btn>
                    </v-row>
                  </v-form>
                </v-expand-transition>
                <v-expand-transition>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-row
                      v-show="expandNr"
                      no-gutters
                      class="mt-1"
                    >
                      <v-col>
                        <v-text-field
                          v-model="numeralRisk"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Add Word"
                        />
                        <v-text-field
                          v-model="numeralRiskDesc"
                          class="ml-2 mr-2"
                          :rules="[rules.required]"
                          label="Description"
                        />
                        <v-btn
                          class="mt-2 ml-2 mb-2"
                          color="primary"
                          :disabled="!valid"
                          :loading="addWordLoading"
                          @click="addSpamWordToJSON(numeralRisk, numeralRiskDesc, 'nr', results.numeralRiskspamWords)"
                        >
                          add
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expand-transition>
                <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="!wordExists ? 'success' : 'error'"
                  outlined
                >
                  {{ message }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import { constants } from '@/shared/constants'
import { mailingService } from '@/shared/services'
import { mapGetters } from 'vuex'

export default {
  name: 'SpamWordManagement',
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 2 || 'Min 2 characters',
        pairWord: value => (value.split(' ').length === 2) || 'Two words requried'
      },
      results: [],
      isLoadingLastUrlShortenerUpdateTime: false,
      loading: false,
      lastClickedTime: null,
      addWordLoading: false,
      removeWordLoading: false,
      wordExists: false,
      valid: false,
      validRemove: false,
      highRisk: '',
      highRiskDesc: '',
      mediumRisk: '',
      mediumRiskDesc: '',
      pairRisk: '',
      pairRiskDesc: '',
      numeralRisk: '',
      numeralRiskDesc: '',
      selectedWordHr: '',
      selectedWordMr: '',
      selectedWordPr: '',
      selectedWordNr: '',
      expandHr: false,
      expandHrDesc: false,
      expandMr: false,
      expandMrDesc: false,
      expandPr: false,
      expandPrDesc: false,
      expandNr: false,
      expandNrDesc: false,
      show: false,
      snackbar: false,
      message: '',
      timeout: 2000
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserId', 'getUser', 'getTenantCrmId'])
  },
  watch: {
    expandHr () {
      this.highRisk = ''
      this.highRiskDesc = ''
    },
    expandMr () {
      this.mediumRisk = ''
      this.mediumRiskDesc = ''
    },
    expandNr () {
      this.numeralRisk = ''
      this.numeralRiskDesc = ''
    },
    expandPr () {
      this.pairRisk = ''
      this.pairRiskDesc = ''
    },
    expandHrDesc () {
      this.highRiskDesc = ''
    },
    expandMrDesc () {
      this.mediumRiskDesc = ''
    },
    expandPrDesc () {
      this.pairRiskDesc = ''
    },
    expandNrDesc () {
      this.numeralRiskDesc = ''
    }
  },
  created () {
    this.loadSpamWords()
    this.loadLastUrlShortenerUpdateTime()
  },
  methods: {
    async handleRefreshUrlShortenersClick () {
      this.loading = true
      await this.updateUrlShorteners()
      await this.loadLastUrlShortenerUpdateTime()
      this.loading = false
    },
    async updateUrlShorteners () {
      const res = await mailingService.updateUrlShorteners()
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: data.errorResponse,
          color: 'error'
        })
      }
    },
    async loadSpamWords () {
      var _this = this
      await mailingService.getSpamWordListJSON()
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            console.log(_this.results.highRiskspamWords)
          }
        })
    },
    async loadLastUrlShortenerUpdateTime () {
      this.isLoadingLastUrlShortenerUpdateTime = true
      const res = await mailingService.getUrlShortenersLastUpdatedTime()
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: data.errorResponse,
          color: 'error'
        })
        this.isLoadingLastUrlShortenerUpdateTime = false
        return
      }
      this.lastClickedTime = res
      this.isLoadingLastUrlShortenerUpdateTime = false
      return res
    },
    async addSpamWordToJSON (word, description, risk, wordList) {
      var _this = this
      var userId = _this.$store.getters['auth/getUserId']()
      var user = _this.$store.getters['auth/getUser']()
      var tenantCrmId = this.$store.getters['auth/getTenantCrmId']()
      _this.addWordLoading = true
      _this.wordExists = wordList.some(obj => obj.Word === word.toLowerCase())
      if (_this.wordExists) {
        _this.addWordLoading = false
        _this.highRisk = ''
        _this.mediumRisk = ''
        _this.pairRisk = ''
        _this.numeralRisk = ''
        _this.snackbar = true
        _this.message = 'This word is duplicate'
      } else {
        await mailingService.addSpamWordToJSON({
          userId: user.userId,
          userTenantId: user.tenantId,
          email: user.email,
          description: description,
          spamWord: word,
          risk: risk
        })
          .then(function (resp) {
            if (resp.errorResponse) {
              _this.$store.commit('setGlobalSnackbar', {
                message: resp.errorResponse,
                color: 'error'
              })
            } else {
              _this.loadSpamWords()
              _this.addWordLoading = false
              _this.snackbar = true
              _this.$refs.form.reset()
              _this.expandHr = false
              _this.expandMr = false
              _this.expandNr = false
              _this.expandPr = false
              _this.message = 'Word added successfully'
            }
          })
      }
    },
    async removeSpamWordToJSON (word, description, risk) {
      var _this = this
      var user = _this.$store.getters['auth/getUser']()
      _this.removeWordLoading = true
      await mailingService.removeSpamWordToJSON({
        userId: user.userId,
        userTenantId: user.tenantId,
        description: description,
        email: user.email,
        spamWord: word.Word,
        risk: risk
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.loadSpamWords()
            _this.removeWordLoading = false
            _this.snackbar = true
            _this.expandHrDesc = false
            _this.expandMrDesc = false
            _this.expandPrDesc = false
            _this.expandNrDesc = false
            _this.$refs.form.reset()
            _this.message = 'Word removed successfully'
          }
        })
    }
  }
}
</script>
