var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3 mt-7",
                  staticStyle: { width: "100%" },
                  attrs: {
                    color: "accent",
                    icon: "mdi-list-status",
                    inline: ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "text-center",
                      attrs: {
                        "no-gutters": "",
                        justify: "end",
                        align: "center"
                      }
                    },
                    [
                      !_vm.isLoadingLastUrlShortenerUpdateTime
                        ? _c("span", [
                            _vm._v(
                              "Url shorteners last updated on: " +
                                _vm._s(
                                  _vm._f("displayDateTimeFormat")(
                                    this.$_global_parseAsUtc(
                                      _vm.lastClickedTime
                                    )
                                  )
                                ) +
                                "."
                            )
                          ])
                        : _vm._e(),
                      _c("span", [
                        _vm._v("Click to update Url shorteners list")
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.loading,
                            color: "primary"
                          },
                          on: { click: _vm.handleRefreshUrlShortenersClick }
                        },
                        [_vm._v("\n            Refresh\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-3", attrs: { elevation: "10" } },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                High risk words\n              "
                                )
                              ]),
                              _c("v-card-subtitle", [
                                _vm._v(
                                  "\n                -50 points\n              "
                                )
                              ]),
                              _c(
                                "v-list",
                                {
                                  staticClass: "overflow-y-auto",
                                  staticStyle: { "max-height": "300px" }
                                },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: _vm.selectedWordHr,
                                        callback: function($$v) {
                                          _vm.selectedWordHr = $$v
                                        },
                                        expression: "selectedWordHr"
                                      }
                                    },
                                    _vm._l(
                                      _vm.results.highRiskspamWords,
                                      function(item, i) {
                                        return _c("v-list-item", {
                                          key: i,
                                          staticStyle: { "font-size": ".9rem" },
                                          attrs: {
                                            value: item,
                                            disabled: _vm.expandHr
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            "on-icon":
                                                              "mdi-checkbox-marked-circle",
                                                            "off-icon":
                                                              "mdi-checkbox-blank-circle-outline",
                                                            "input-value": active,
                                                            disabled:
                                                              _vm.expandHr
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.Word)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                { staticClass: "mt-2 ml-2 pb-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "accent",
                                        fab: "",
                                        "x-small": "",
                                        disabled: _vm.expandHrDesc
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandHr = !_vm.expandHr
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandHr
                                              ? "mdi-plus"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(!_vm.expandHr ? "Add" : "Close")
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-15",
                                      attrs: {
                                        color: "error",
                                        fab: "",
                                        "x-small": "",
                                        disabled:
                                          _vm.selectedWordHr === undefined ||
                                          _vm.selectedWordHr === "" ||
                                          _vm.expandHr
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandHrDesc = !_vm.expandHrDesc
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandHrDesc
                                              ? "mdi-delete"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.expandHrDesc ? "Remove" : "Close"
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.validRemove,
                                        callback: function($$v) {
                                          _vm.validRemove = $$v
                                        },
                                        expression: "validRemove"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandHrDesc,
                                              expression: "expandHrDesc"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "ml-2 mr-2",
                                            attrs: {
                                              rules: [_vm.rules.required],
                                              label: "Description"
                                            },
                                            model: {
                                              value: _vm.highRiskDesc,
                                              callback: function($$v) {
                                                _vm.highRiskDesc = $$v
                                              },
                                              expression: "highRiskDesc"
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-2 mb-2",
                                              attrs: {
                                                color: "primary",
                                                disabled: !_vm.validRemove,
                                                loading: _vm.addWordLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeSpamWordToJSON(
                                                    _vm.selectedWordHr,
                                                    _vm.highRiskDesc,
                                                    "hr"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      remove\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandHr,
                                              expression: "expandHr"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Add Word"
                                                },
                                                model: {
                                                  value: _vm.highRisk,
                                                  callback: function($$v) {
                                                    _vm.highRisk = $$v
                                                  },
                                                  expression: "highRisk"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Description"
                                                },
                                                model: {
                                                  value: _vm.highRiskDesc,
                                                  callback: function($$v) {
                                                    _vm.highRiskDesc = $$v
                                                  },
                                                  expression: "highRiskDesc"
                                                }
                                              }),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-2 ml-2 mb-2",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: !_vm.valid,
                                                    loading: _vm.addWordLoading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addSpamWordToJSON(
                                                        _vm.highRisk,
                                                        _vm.highRiskDesc,
                                                        "hr",
                                                        _vm.results
                                                          .highRiskspamWords
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        add\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-3", attrs: { elevation: "10" } },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                Medium risk words\n              "
                                )
                              ]),
                              _c("v-card-subtitle", [
                                _vm._v(
                                  "\n                -5 points\n              "
                                )
                              ]),
                              _c(
                                "v-list",
                                {
                                  staticClass: "overflow-y-auto",
                                  staticStyle: { "max-height": "300px" }
                                },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: _vm.selectedWordMr,
                                        callback: function($$v) {
                                          _vm.selectedWordMr = $$v
                                        },
                                        expression: "selectedWordMr"
                                      }
                                    },
                                    _vm._l(
                                      _vm.results.mediumRiskspamWords,
                                      function(item, i) {
                                        return _c("v-list-item", {
                                          key: i,
                                          staticStyle: { "font-size": ".9rem" },
                                          attrs: {
                                            value: item,
                                            disabled: _vm.expandMr
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            "on-icon":
                                                              "mdi-checkbox-marked-circle",
                                                            "off-icon":
                                                              "mdi-checkbox-blank-circle-outline",
                                                            "input-value": active,
                                                            disabled:
                                                              _vm.expandMr
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.Word)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                { staticClass: "mt-2 ml-2 pb-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "accent",
                                        fab: "",
                                        "x-small": "",
                                        disabled: _vm.expandMrDesc
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandMr = !_vm.expandMr
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandMr
                                              ? "mdi-plus"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(!_vm.expandMr ? "Add" : "Close")
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-15",
                                      attrs: {
                                        color: "error",
                                        fab: "",
                                        "x-small": "",
                                        disabled:
                                          _vm.selectedWordMr === undefined ||
                                          _vm.selectedWordMr === "" ||
                                          _vm.expandMr
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandMrDesc = !_vm.expandMrDesc
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandMrDesc
                                              ? "mdi-delete"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.expandMrDesc ? "Remove" : "Close"
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.validRemove,
                                        callback: function($$v) {
                                          _vm.validRemove = $$v
                                        },
                                        expression: "validRemove"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandMrDesc,
                                              expression: "expandMrDesc"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "ml-2 mr-2",
                                            attrs: {
                                              rules: [_vm.rules.required],
                                              label: "Description"
                                            },
                                            model: {
                                              value: _vm.mediumRiskDesc,
                                              callback: function($$v) {
                                                _vm.mediumRiskDesc = $$v
                                              },
                                              expression: "mediumRiskDesc"
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-2 mb-2",
                                              attrs: {
                                                color: "primary",
                                                disabled: !_vm.validRemove,
                                                loading: _vm.addWordLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeSpamWordToJSON(
                                                    _vm.selectedWordMr,
                                                    _vm.mediumRiskDesc,
                                                    "mr"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      remove\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandMr,
                                              expression: "expandMr"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Add Word"
                                                },
                                                model: {
                                                  value: _vm.mediumRisk,
                                                  callback: function($$v) {
                                                    _vm.mediumRisk = $$v
                                                  },
                                                  expression: "mediumRisk"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Description"
                                                },
                                                model: {
                                                  value: _vm.mediumRiskDesc,
                                                  callback: function($$v) {
                                                    _vm.mediumRiskDesc = $$v
                                                  },
                                                  expression: "mediumRiskDesc"
                                                }
                                              }),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-2 ml-2 mb-2",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: !_vm.valid,
                                                    loading: _vm.addWordLoading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addSpamWordToJSON(
                                                        _vm.mediumRisk,
                                                        _vm.mediumRiskDesc,
                                                        "mr",
                                                        _vm.results
                                                          .mediumRiskspamWords
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        add\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-3", attrs: { elevation: "10" } },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                Pair words\n              "
                                )
                              ]),
                              _c("v-card-subtitle", [
                                _vm._v(
                                  "\n                -5 points\n              "
                                )
                              ]),
                              _c(
                                "v-list",
                                {
                                  staticClass: "overflow-y-auto",
                                  staticStyle: { "max-height": "300px" }
                                },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: _vm.selectedWordPr,
                                        callback: function($$v) {
                                          _vm.selectedWordPr = $$v
                                        },
                                        expression: "selectedWordPr"
                                      }
                                    },
                                    _vm._l(
                                      _vm.results.pairRiskspamWords,
                                      function(item, i) {
                                        return _c("v-list-item", {
                                          key: i,
                                          staticStyle: { "font-size": ".9rem" },
                                          attrs: {
                                            value: item,
                                            disabled: _vm.expandPr
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            "on-icon":
                                                              "mdi-checkbox-marked-circle",
                                                            "off-icon":
                                                              "mdi-checkbox-blank-circle-outline",
                                                            "input-value": active,
                                                            disabled:
                                                              _vm.expandPr
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.Word)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                { staticClass: "mt-2 ml-2 pb-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "accent",
                                        fab: "",
                                        "x-small": "",
                                        disabled: _vm.expandPrDesc
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandPr = !_vm.expandPr
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandPr
                                              ? "mdi-plus"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(!_vm.expandPr ? "Add" : "Close")
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-15",
                                      attrs: {
                                        color: "error",
                                        fab: "",
                                        "x-small": "",
                                        disabled:
                                          _vm.selectedWordPr === undefined ||
                                          _vm.selectedWordPr === "" ||
                                          _vm.expandPr
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandPrDesc = !_vm.expandPrDesc
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandPrDesc
                                              ? "mdi-delete"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.expandPrDesc ? "Remove" : "Close"
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.validRemove,
                                        callback: function($$v) {
                                          _vm.validRemove = $$v
                                        },
                                        expression: "validRemove"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandPrDesc,
                                              expression: "expandPrDesc"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "ml-2 mr-2",
                                            attrs: {
                                              rules: [_vm.rules.required],
                                              label: "Description"
                                            },
                                            model: {
                                              value: _vm.pairRiskDesc,
                                              callback: function($$v) {
                                                _vm.pairRiskDesc = $$v
                                              },
                                              expression: "pairRiskDesc"
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-2 mb-2",
                                              attrs: {
                                                color: "primary",
                                                disabled: !_vm.validRemove,
                                                loading: _vm.addWordLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeSpamWordToJSON(
                                                    _vm.selectedWordPr,
                                                    _vm.pairRiskDesc,
                                                    "pr"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      remove\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandPr,
                                              expression: "expandPr"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.pairWord
                                                  ],
                                                  label: "Add Word"
                                                },
                                                model: {
                                                  value: _vm.pairRisk,
                                                  callback: function($$v) {
                                                    _vm.pairRisk = $$v
                                                  },
                                                  expression: "pairRisk"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Description"
                                                },
                                                model: {
                                                  value: _vm.pairRiskDesc,
                                                  callback: function($$v) {
                                                    _vm.pairRiskDesc = $$v
                                                  },
                                                  expression: "pairRiskDesc"
                                                }
                                              }),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-2 ml-2 mb-2",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: !_vm.valid,
                                                    loading: _vm.addWordLoading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addSpamWordToJSON(
                                                        _vm.pairRisk,
                                                        _vm.pairRiskDesc,
                                                        "pr",
                                                        _vm.results
                                                          .pairRiskspamWords
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        add\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-3", attrs: { elevation: "10" } },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                Numerals\n              "
                                )
                              ]),
                              _c("v-card-subtitle", [
                                _vm._v(
                                  "\n                -5 points\n              "
                                )
                              ]),
                              _c(
                                "v-list",
                                {
                                  staticClass: "overflow-y-auto",
                                  staticStyle: { "max-height": "300px" }
                                },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: _vm.selectedWordNr,
                                        callback: function($$v) {
                                          _vm.selectedWordNr = $$v
                                        },
                                        expression: "selectedWordNr"
                                      }
                                    },
                                    _vm._l(
                                      _vm.results.numeralRiskspamWords,
                                      function(item, i) {
                                        return _c("v-list-item", {
                                          key: i,
                                          staticStyle: { "font-size": ".9rem" },
                                          attrs: {
                                            value: item,
                                            disabled: _vm.expandNr
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var active = ref.active
                                                  return [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            "on-icon":
                                                              "mdi-checkbox-marked-circle",
                                                            "off-icon":
                                                              "mdi-checkbox-blank-circle-outline",
                                                            "input-value": active,
                                                            disabled:
                                                              _vm.expandNr
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.Word)
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                { staticClass: "mt-2 ml-2 pb-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "accent",
                                        fab: "",
                                        "x-small": "",
                                        disabled: _vm.expandNrDesc
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandNr = !_vm.expandNr
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandNr
                                              ? "mdi-plus"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      _vm._s(!_vm.expandNr ? "Add" : "Close")
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-15",
                                      attrs: {
                                        color: "error",
                                        fab: "",
                                        "x-small": "",
                                        disabled:
                                          _vm.selectedWordNr === undefined ||
                                          _vm.selectedWordNr === "" ||
                                          _vm.expandNr
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expandNrDesc = !_vm.expandNrDesc
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.expandNrDesc
                                              ? "mdi-delete"
                                              : "mdi-close"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Remove")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.validRemove,
                                        callback: function($$v) {
                                          _vm.validRemove = $$v
                                        },
                                        expression: "validRemove"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandNrDesc,
                                              expression: "expandNrDesc"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "ml-2 mr-2",
                                            attrs: {
                                              rules: [_vm.rules.required],
                                              label: "Description"
                                            },
                                            model: {
                                              value: _vm.numeralRiskDesc,
                                              callback: function($$v) {
                                                _vm.numeralRiskDesc = $$v
                                              },
                                              expression: "numeralRiskDesc"
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-2 mb-2",
                                              attrs: {
                                                color: "primary",
                                                disabled: !_vm.validRemove,
                                                loading: _vm.addWordLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeSpamWordToJSON(
                                                    _vm.selectedWordNr,
                                                    _vm.numeralRiskDesc,
                                                    "nr"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      remove\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.expandNr,
                                              expression: "expandNr"
                                            }
                                          ],
                                          staticClass: "mt-1",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Add Word"
                                                },
                                                model: {
                                                  value: _vm.numeralRisk,
                                                  callback: function($$v) {
                                                    _vm.numeralRisk = $$v
                                                  },
                                                  expression: "numeralRisk"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Description"
                                                },
                                                model: {
                                                  value: _vm.numeralRiskDesc,
                                                  callback: function($$v) {
                                                    _vm.numeralRiskDesc = $$v
                                                  },
                                                  expression: "numeralRiskDesc"
                                                }
                                              }),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-2 ml-2 mb-2",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: !_vm.valid,
                                                    loading: _vm.addWordLoading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addSpamWordToJSON(
                                                        _vm.numeralRisk,
                                                        _vm.numeralRiskDesc,
                                                        "nr",
                                                        _vm.results
                                                          .numeralRiskspamWords
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        add\n                      "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-snackbar",
                                {
                                  attrs: {
                                    timeout: _vm.timeout,
                                    color: !_vm.wordExists
                                      ? "success"
                                      : "error",
                                    outlined: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "action",
                                      fn: function(ref) {
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._b(
                                              {
                                                attrs: { text: "" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.snackbar = false
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                "\n                    Close\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.snackbar,
                                    callback: function($$v) {
                                      _vm.snackbar = $$v
                                    },
                                    expression: "snackbar"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.message) +
                                      "\n\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }